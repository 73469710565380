<template>
  <ion-page>
    <Header name="News" />
    <ion-content :fullscreen="true">
      <ion-refresher slot="fixed" @ionRefresh="doRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>

      <div id="container">

        <ion-loading
          :is-open="isLoading"
          message="Lade Daten.."
          @onDidDismiss="setLoading(false)"
          :duration="3000"
        >
        </ion-loading>

        <div class="go-back" @click="goBackToNewFeed()">
          <span class="font-face--brand">Zurück zur Übersicht</span>
        </div>

        <div 
          class="blog-header"
          :class="{'zero-height': !hasImage(entry)}" 
          v-if="!showNoEntry"
        >
          <div v-if="hasImage(entry)" class="details-image">
            <img :src="getImageUrl(entry.imageUrl)" width="600" height="450" />
          </div>
        </div>

        <ion-grid style="padding: 0">

          <ion-row v-if="showNoEntry" class="no-data">
            <ion-col>
              <div class="card">
                <h3>Fehler beim Laden des Artikels</h3>
              </div>
            </ion-col>
          </ion-row>

          <div v-if="showArticle">
            <ion-row>
              <ion-col style="padding: 0">
                <div class="card news-entry">
                  <div class="content">
                    <div class="text">
                      <h1 class="main-title font-face--merrisans light">{{ entry.title }}</h1>
                      <h3 v-if="entry.teaser" class="subtitle font-face--merrisans light">{{ entry.teaser }}</h3>
                      <div class="font-resize" @click.prevent="resizeFont()">Aa</div>
                      <p 
                        class="font-face--merrisans light" 
                        :class="{'font-normal': fontSize === 1, 'font-large': fontSize === 2, 'font-huge': fontSize === 3}"
                        v-html="getHTML(entry.body)"
                      />
                    </div>
                  </div>
                  <div v-if="showSlider" class="slider">
                    <ion-slides pager="true" :options="slideOptions">
                      <ion-slide 
                        v-for="(item, i) in entry.sliderContent" 
                        :key="i"
                        @click="openImage(item)"
                      >
                        <ion-card><img :src="item.url"></ion-card>
                      </ion-slide>
                    </ion-slides>
                  </div>
                  <div class="meta font-face--merrisans light">
                    <time>{{ getFormattedDate(entry.datetime) }}</time>
                    <span v-if="entry.subtitle !== null">
                      &nbsp; | &nbsp; {{ entry.subtitle }}
                    </span>
                  </div>
                </div>
              </ion-col>
            </ion-row>

          </div>

        </ion-grid>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { 
  IonPage, 
  IonCard,
  IonContent, 
  IonCol, 
  IonGrid, 
  IonRow, 
  IonLoading, 
  IonRefresher, 
  IonRefresherContent,
  IonSlide, 
  IonSlides 
} from '@ionic/vue';

import Header from '@/components/Header.vue';

// @ts-ignore
import filterHelperMethods from '@/helper/filter'
// @ts-ignore
import viewHelperMethods from '@/helper/view'

// @ts-ignore
import * as api from '@/service/index.js'

export default  {
  name: 'ArticleDetail',
  components: { 
    Header, 
    IonContent, 
    IonPage, 
    IonCard, 
    IonCol, 
    IonGrid, 
    IonRow,
    IonLoading, 
    IonRefresher, 
    IonRefresherContent,
    IonSlide,
    IonSlides
  },
  data () {
    return {
      isLoading: false,
      entry: {},
      firstLoadDone: false,
      fontSize: 1,
      slideOptions: {
        slidesPerView: 1.5,
        centeredSlides: true,
        loop: true,
        spaceBetween: 5,
        speed: 400,
        delay: 3000,
        autoplay: true
        // disableOnInteraction: false
      }
    }
  },
  computed: {
    showNoEntry (): boolean {
      return (this as any).firstLoadDone && !(this as any).entry.id 
    },
    showArticle (): boolean {
      return (this as any).firstLoadDone && (this as any).entry.id 
    },
    showSlider (): boolean {
      return (this as any).showArticle && (this as any).entry?.sliderContent?.length
    }
  },
  methods: {
    ...filterHelperMethods,
    ...viewHelperMethods,
    goBackToNewFeed () {
      (this as any).$router.push({ path: '/news' });
    },
    reset () {
      (this as any).isLoading = false;
      (this as any).entry = {}; 
    },
    setLoading (val: boolean) {
      console.log('set loading %s', val);
      (this as any).isLoading = val;
    },
    resizeFont () {
      if ((this as any).fontSize === 3) {
        (this as any).fontSize = 1
      } else {
        (this as any).fontSize +=1
      }

      localStorage.setItem('fs', (this as any).fontSize)
    },
    openImage (image: any) {
      image?.url && console.log(image.url)
    },
    async loadArticle (id: number, event: any) {
      console.log('start load');
      const vm = (this as any);
      vm.setLoading(true);

      let fs: any = localStorage.getItem('fs')
      if (fs !== null) {
        try {
          fs = parseInt(fs)
        } catch(err) {
          console.log(err)
        }

        if (fs > 0 && fs < 4) {
          vm.fontSize = fs
        }
      }

      try {
        const data = await api.getNewsEntry(id);
        console.log(data);

        if (data.data.id) {
          vm.entry = data.data
        }
      } catch (error) {
        vm.handleAxiosError(error);
        console.log(error);
      }

      console.log('end load');
      setTimeout(function() {
        vm.setLoading(false);
      }, 500);

      if (vm.entry.id) {
        const article = {
          id: vm.entry.id,
          type: 'news',
          title: vm.entry.title,
          timestamp: new Date().valueOf(),
          public: [1, 2].includes(vm.entry.availableArea)
        };

        let history: any = localStorage.getItem('history');
        if (history !== null) {
            try {
                history = JSON.parse(history);
            } catch(err) {
                console.log(err);
                history = [];
            }
        } else {
            history = [];
        }

        if (history.length) {
          let duplicated = false;
          for (let i in history) {
            if (history[i].id === article.id && history[i].type === article.type) {
              history[i].timestamp = article.timestamp;
              duplicated = true;
              break;
            }  
          }

          if (!duplicated) {
            history.push(article);
          }
        } else {
          history.push(article);
        }

        console.log(history);

        localStorage.setItem('history', JSON.stringify(history));
      }

      vm.firstLoadDone = true;

      if (event?.type === 'ionrefresh') {
        event.target.complete();
      }
    },
    async doRefresh (event: any) {
      (this as any).reset();
      (this as any).loadArticle((this as any).$route.params.id, event);
    },
    handleAxiosError (err: any) {
      if (err && err.message) {
        if (err.message.indexOf('401') !== -1) {
          console.log('unauthorized');
          localStorage.removeItem('jwt');
          localStorage.removeItem('jwt_expires');
          (this as any).$router.push('/auth');
        }
      }
    }
  },
  ionViewWillLeave () {
    console.log('[ionView] will leave article detail');
  },
  ionViewDidEnter () {
    console.log('[ionView] entered article detail');
  },
  mounted () {
    console.log('Loading article ', (this as any).$route.params.id);
    (this as any).reset();
    (this as any).loadArticle((this as any).$route.params.id);
  },
  watch: {
    $route() {
      // react to route changes...
      if ((this as any).$route.params.id) {
        console.log('View updated, loading article ', (this as any).$route.params.id);
        (this as any).reset();
        (this as any).loadArticle((this as any).$route.params.id);
      }
    }
  }
}
</script>

<style scoped lang="less">
.load-more {
  margin-top: 15px;
}

.go-back {
  height: 50px;
  width: 100%;
  padding: 0 15px;
  background: #fff;
  color: var(--text-color-blue);
  font-size: 20px;

  span {
    line-height: 54px;
    position: relative;
    margin-left: 27px;

    &:before {
      left: -32px;
      bottom: 5.5px;
      position: absolute;
      width: 22px;
      height: 22px;
      background-image: url('~@/assets/img/icons/arrow-next-blue.svg');
      transform: rotate(180deg);
      background-repeat: no-repeat;
      content: "";
    }
  }
}

h1.main-title {
  font-size: 28px;
  line-height: 37.7px;
  margin-top: 0;
}

p {
  font-size: 15px;
  line-height: 28.5px;
}

.font-resize {
  text-align: right;
  margin: -10px 0 0 0;
  font-size: 20px;
}

.blog-header {
  min-height: 130px;
  width: 100%;
  padding: 0;
  margin-bottom: -3px;

  img {
    width: 100%;
    height: auto;
  }

  h1 {
    color: #fff;
    font-weight: 400;
    font-size: 22px;
    line-height: 30px;
  }
}

.zero-height {
  min-height: 0 !important;
  height: 0 !important;
}

@media (prefers-color-scheme: dark) {
  .card {
    background: var(--background-black);

    h1,h3 {
      color: #fff;
    }

    p {
      color: #fff;
    }
  }

  .go-back {
    background: #000;
    color: var(--text-color-blue);

    span {
      &:before {
        background-image: url('~@/assets/img/icons/arrow-next-lightblue.svg');
      }
    }
  }
}
body.dark {
  .card {
    background: var(--background-black);

    h1,h3 {
      color: #fff;
    }

    p {
      color: #fff;
    }
  }

  .go-back {
    background: #000;
    color: var(--text-color-blue);

    span {
      &:before {
        background-image: url('~@/assets/img/icons/arrow-next-lightblue.svg');
      }
    }
  }
}

.slider {
  ion-card {
    width: 100%;
    min-height: 160px;
    box-shadow: none;

    img {
      object-fit: cover;
      width: 100%;
      transform: scale(1.1, 1.1);
      transition: 400ms all ease-in-out;
    }
  }

  .swiper-slide {
    transition: 400ms all ease-in-out;
  }

  .swiper-slide-active {
    ion-card img {
      transform: scale(1, 1);
    }
  }
}
</style>

<style>
  .swiper-pagination-bullet-active {
    background: var(--text-color-blue) !important;
  }
</style>