
import { 
  IonPage, 
  IonCard,
  IonContent, 
  IonCol, 
  IonGrid, 
  IonRow, 
  IonLoading, 
  IonRefresher, 
  IonRefresherContent,
  IonSlide, 
  IonSlides 
} from '@ionic/vue';

import Header from '@/components/Header.vue';

// @ts-ignore
import filterHelperMethods from '@/helper/filter'
// @ts-ignore
import viewHelperMethods from '@/helper/view'

// @ts-ignore
import * as api from '@/service/index.js'

export default  {
  name: 'ArticleDetail',
  components: { 
    Header, 
    IonContent, 
    IonPage, 
    IonCard, 
    IonCol, 
    IonGrid, 
    IonRow,
    IonLoading, 
    IonRefresher, 
    IonRefresherContent,
    IonSlide,
    IonSlides
  },
  data () {
    return {
      isLoading: false,
      entry: {},
      firstLoadDone: false,
      fontSize: 1,
      slideOptions: {
        slidesPerView: 1.5,
        centeredSlides: true,
        loop: true,
        spaceBetween: 5,
        speed: 400,
        delay: 3000,
        autoplay: true
        // disableOnInteraction: false
      }
    }
  },
  computed: {
    showNoEntry (): boolean {
      return (this as any).firstLoadDone && !(this as any).entry.id 
    },
    showArticle (): boolean {
      return (this as any).firstLoadDone && (this as any).entry.id 
    },
    showSlider (): boolean {
      return (this as any).showArticle && (this as any).entry?.sliderContent?.length
    }
  },
  methods: {
    ...filterHelperMethods,
    ...viewHelperMethods,
    goBackToNewFeed () {
      (this as any).$router.push({ path: '/news' });
    },
    reset () {
      (this as any).isLoading = false;
      (this as any).entry = {}; 
    },
    setLoading (val: boolean) {
      console.log('set loading %s', val);
      (this as any).isLoading = val;
    },
    resizeFont () {
      if ((this as any).fontSize === 3) {
        (this as any).fontSize = 1
      } else {
        (this as any).fontSize +=1
      }

      localStorage.setItem('fs', (this as any).fontSize)
    },
    openImage (image: any) {
      image?.url && console.log(image.url)
    },
    async loadArticle (id: number, event: any) {
      console.log('start load');
      const vm = (this as any);
      vm.setLoading(true);

      let fs: any = localStorage.getItem('fs')
      if (fs !== null) {
        try {
          fs = parseInt(fs)
        } catch(err) {
          console.log(err)
        }

        if (fs > 0 && fs < 4) {
          vm.fontSize = fs
        }
      }

      try {
        const data = await api.getNewsEntry(id);
        console.log(data);

        if (data.data.id) {
          vm.entry = data.data
        }
      } catch (error) {
        vm.handleAxiosError(error);
        console.log(error);
      }

      console.log('end load');
      setTimeout(function() {
        vm.setLoading(false);
      }, 500);

      if (vm.entry.id) {
        const article = {
          id: vm.entry.id,
          type: 'news',
          title: vm.entry.title,
          timestamp: new Date().valueOf(),
          public: [1, 2].includes(vm.entry.availableArea)
        };

        let history: any = localStorage.getItem('history');
        if (history !== null) {
            try {
                history = JSON.parse(history);
            } catch(err) {
                console.log(err);
                history = [];
            }
        } else {
            history = [];
        }

        if (history.length) {
          let duplicated = false;
          for (let i in history) {
            if (history[i].id === article.id && history[i].type === article.type) {
              history[i].timestamp = article.timestamp;
              duplicated = true;
              break;
            }  
          }

          if (!duplicated) {
            history.push(article);
          }
        } else {
          history.push(article);
        }

        console.log(history);

        localStorage.setItem('history', JSON.stringify(history));
      }

      vm.firstLoadDone = true;

      if (event?.type === 'ionrefresh') {
        event.target.complete();
      }
    },
    async doRefresh (event: any) {
      (this as any).reset();
      (this as any).loadArticle((this as any).$route.params.id, event);
    },
    handleAxiosError (err: any) {
      if (err && err.message) {
        if (err.message.indexOf('401') !== -1) {
          console.log('unauthorized');
          localStorage.removeItem('jwt');
          localStorage.removeItem('jwt_expires');
          (this as any).$router.push('/auth');
        }
      }
    }
  },
  ionViewWillLeave () {
    console.log('[ionView] will leave article detail');
  },
  ionViewDidEnter () {
    console.log('[ionView] entered article detail');
  },
  mounted () {
    console.log('Loading article ', (this as any).$route.params.id);
    (this as any).reset();
    (this as any).loadArticle((this as any).$route.params.id);
  },
  watch: {
    $route() {
      // react to route changes...
      if ((this as any).$route.params.id) {
        console.log('View updated, loading article ', (this as any).$route.params.id);
        (this as any).reset();
        (this as any).loadArticle((this as any).$route.params.id);
      }
    }
  }
}
